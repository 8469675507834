<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "jianHunTiaoZhan",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(金乌岛场景会失败,退出金乌岛再初始环境,如果失败看菜单初始环境教程)(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到剑魂挑战页",
                            "#按图1说明参数:",
                            "1.输入挑战次数,到达次数后自动停止,不填写一直刷",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#注意识别区域,防止误使用药品食物(图1)",
                            "#进入场景后移动到黑点处卡BUG(图2)",
                            "#注意报名进入场景后,镜头应该面向的是报名NPC那一面,如图2这样,如果不是,是卡游戏bug了,返回人物重新进入",
                            "#以上设置好,开始任务",
                        ],
                        img:[
                            "2.png",
                            "3.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
